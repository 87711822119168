import * as React from "react";
import {Grid, Link} from "@mui/material";

export function SignInHeader() {

    return (
        <Grid container direction="row" style={{width: '100%', textAlign: 'center', marginTop: '20px'}}>
            <Grid item xs={12} md={12}>
                <Link href={window.location.href}>
                    <img src={process.env.PUBLIC_URL + '/EWE_on_dark.svg'} alt="EWE Logo" style={{
                        width: '103px',
                        height: '41px'
                    }}/>
                </Link>
                <div style={{
                    fontSize: '1.3rem',
                    fontWeight: 'bold',
                    display: 'inline-flex',
                    marginLeft: '5px',
                    marginBottom: '0px',
                    verticalAlign: 'text-bottom',
                }}>Energize KMS</div>
            </Grid>
        </Grid>
    );
}