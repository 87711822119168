import React from "react";
import {Alert} from "@mui/material";

export interface IErrorAlertProps {
  readonly errorMessage: string,
}

export function ErrorAlert(props: IErrorAlertProps) {

  return (
    <Alert severity="error" variant={"filled"}>{props.errorMessage}</Alert>
  );

}
