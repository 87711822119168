import React from 'react';
import './amplify'
import {Authenticator} from "@aws-amplify/ui-react";
import '@aws-amplify/ui-react/styles.css';
import Footer from "./components/organisms/footer";
import {EmailAssistentPage} from "./components/pages/email-assistent-page";
import Header from "./components/organisms/header";
import {SignInHeader} from "./components/organisms/SignInHeader";
import {Toaster} from "react-hot-toast";
import {SignInFooter} from "./components/organisms/SignInFooter";

function App() {

    const components = {
        SignIn: {
            Header: SignInHeader,
            Footer: SignInFooter
        },

    };

    React.useEffect(() => {
        if (process.env.REACT_APP_ENABLE_MANUAL_LOGIN === 'false') {
            require('./hideAmplifyLogin.css');
        }
      }, []);

    React.useEffect(() => {
        if ( process.env.REACT_APP_ENABLE_MATOMO === 'false') {
            return;
        } else {
            // @ts-ignore
            var _mtm = window._mtm = window._mtm || [];
            _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
            (function() {
                var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
                g.async=true; g.src='https://track.ewe.de/js/container_XRnxSKs0.js'; // @ts-ignore
                s.parentNode.insertBefore(g,s);
            })();
        }

    }, []);

    return (
        <Authenticator hideSignUp components={components}>
            {({ signOut, user }) => (
                <>
                    <div><Toaster/></div>
                    <Header email={user?.attributes?.email}></Header>
                    <EmailAssistentPage></EmailAssistentPage>
                    <Footer></Footer>
                </>
            )}
        </Authenticator>
    );
}
export default App;
