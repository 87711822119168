import * as React from "react";
import {Box, Card, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText, Typography} from "@mui/material";
import {AttachFile, ContentCopy} from "@mui/icons-material";
import toast from "react-hot-toast";

export interface IDocumentCardProps {
  readonly loading: boolean,
  readonly documents: string[],
}

const DocumentsCard: React.FC<IDocumentCardProps> = (props: IDocumentCardProps) => {

    function copyToClipboard(text: string) {
        navigator.clipboard.writeText(text)
            .then(() => {
                toast.success('In Zwischenablage kopiert');
                }
            );
    }

  function Documents() {
    if (props.documents.length > 0) {
      return (
        <>
          {props.documents.map((fact, index) =>
            <ListItem key={index} secondaryAction={
                <IconButton edge="end" aria-label="copy" onClick={() => copyToClipboard(fact)}>
                    <ContentCopy />
                </IconButton>
            }>
              <ListItemAvatar>
                <AttachFile/>
              </ListItemAvatar>
              <ListItemText primary={fact} />
            </ListItem>
          )}
        </>
      )
    }
  }

  return (
    <Card>
      <Typography sx={{ fontSize: 16, fontWeight: 'bold', padding: '8px' }}>Relevante Inhalte zur Kundenanfrage</Typography>
      <Divider light></Divider>
      <List>
        {(!props.loading && props.documents.length > 0) ? (
          Documents()
        ) : (
          <Box sx={{height: '96px'}}></Box>
        )}
      </List>
    </Card>
  );
};

export default DocumentsCard;