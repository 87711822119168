import {createTheme} from "@mui/material";

const theme = createTheme({
    palette: {
        primary: {
            main: '#54C0B5',
        },
        secondary: {
            main: '#FFD700',
        },
    },
    typography: {
        fontFamily: ['Open Sans', 'Roboto', 'Helvetica Neue'].join(','),
        body1: {
            fontWeight: 400,
            fontFamily: '1rem',
            color: '#333a46',
        }
    },
    components: {
        MuiInput: {
            styleOverrides: {
                root: {
                    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                        {
                            display: 'none',
                        },
                    '& input[type=number]': {
                        MozAppearance: 'textfield',
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    ':hover': {
                        backgroundColor: 'rgb(84, 192, 181, 0.25)'
                    },
                },
            },
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    ':hover': {
                        backgroundColor: 'rgb(84, 192, 181, 0.25)'
                    },
                }
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    color: '#54C0B5',
                    textTransform: 'none',
                },
            },
        },
    },
    spacing: (factor: any) => `${0.25 * factor}rem`, // (Bootstrap strategy)
});

export type LocalTheme = typeof theme;

export default theme;

declare module '@emotion/react' {
    export interface Theme extends LocalTheme {
    }
}