import * as React from "react";
import {
    Box,
    Card,
    Chip,
    Divider, Grid,
    styled,
    Switch,
    Typography
} from "@mui/material";
import CardSkeleton from "../molecules/card-skeleton";
import {useState} from "react";

export interface ISummaryCardProps {
    readonly loading: boolean,
    readonly concerns: string[] | undefined,
    readonly originalEmail: any,
    originalEmailMessage: string
}

const ConcernChip = styled(Chip)(({theme}) => ({
    textAlign: 'center',
    ...theme.typography.button,
    marginLeft: 8,
    marginTop: 4,
    marginBottom: 4,
    textTransform: 'none',
}));

const ConcernChipBox = styled(Box)(({theme}) => ({
    padding: theme.spacing(1)
}));

const SummaryCard: React.FC<ISummaryCardProps> = (props: ISummaryCardProps) => {

    const [showSummary, setShowSummary] = useState(true);

    function ConcernChips() {

        if (props.concerns && props.concerns?.length > 0) {
            return (
                <>
                    <ConcernChipBox>
                        {props.concerns?.map((concern, index) =>
                            <ConcernChip key={index} label={concern} color="primary" variant="outlined"/>
                        )}
                    </ConcernChipBox>
                </>
            )
        }
    }

    return (
        <Card>
            <Grid container>
                <Grid md={6}>
                    <Typography sx={{fontSize: 16, fontWeight: 'bold', padding: '8px'}}>Zusammenfassung</Typography>
                </Grid>
                <Grid md={6} style={{textAlign: 'right'}}>
                    Zusammenfassung anzeigen
                    <Switch checked={showSummary} onChange={() => setShowSummary(!showSummary)} />
                </Grid>
            </Grid>


            <Divider light></Divider>
            <Box justifyContent="left">
                {(!props.loading && props.concerns && props.concerns?.length > 0) ? (
                    ConcernChips()
                ) : (
                    <Box sx={{height: '48px'}}></Box>
                )}
            </Box>
            <Divider light></Divider>
            {!showSummary && (
                <>
                    <div style={{padding: '20px', minHeight: '480px', overflowY: 'auto'}}>
                        <div dangerouslySetInnerHTML={{__html: props.originalEmailMessage.replaceAll('\n', '<br />')}}/>
                    </div>
                </>
            )}
            {showSummary && (
                <>
                    {!props.loading ? (
                        <div style={{padding: '20px', minHeight: '480px', overflowY: 'auto'}}>
                            {props.originalEmail['Kernanliegen'] && (<h3 style={{marginTop: '0px'}}>Kernanliegen</h3>)}
                            {props.originalEmail['Kernanliegen']}

                            {props.originalEmail['Kundendaten'] && (<h3>Kundendaten</h3>)}
                            {props.originalEmail['Kundendaten']?.map((c: string) => {
                                return <>
                                    {c} <br/>
                                </>
                            })}

                            {props.originalEmail['Zusammenfassung'] && (<h3>Zusammenfassung</h3>)}

                            {props.originalEmail['Zusammenfassung']?.map((s: any) => {
                                return <div style={{marginBottom: '10px'}}>
                                    {s['Datum']} {s['Sender']}: {s['Titel']} <br/>
                                    <div style={{marginLeft: '0px'}}>
                                        <ul>
                                            {s['Inhalt']?.map((i: string) => {
                                                return <li>
                                                    {i}
                                                </li>

                                            })}
                                        </ul>
                                    </div>
                                </div>
                            })}

                        </div>


                    ) : (
                        <>
                        <CardSkeleton spacing={52}/>
                        </>
                    )}
                </>
            )}

        </Card>
    );
};

export default SummaryCard;