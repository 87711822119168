import React from 'react';
import LogoutIcon from "@mui/icons-material/Logout";
import {signOut} from "../../amplify";
import {Box, Button, Grid, Link, Stack, styled, Tooltip, Zoom} from "@mui/material";

export interface IHeaderProps {
  readonly email?: string,
}

const StyledHeader = styled('div')({
  paddingBottom: '8px',
  paddingTop: '8px',
  textAlign: 'center',
  color: '#FFFFFF',
  background: '#5A5F5F',
  boxShadow: 'rgba(0, 0, 0, 0.35) 0 1px 3px',
});

const Header: React.FC<IHeaderProps> = (props: IHeaderProps) => {

  return (
    <StyledHeader>
      <Grid container direction="row">
        <Grid item xs={5}>
          <Link href={window.location.href}>
            <img src={process.env.PUBLIC_URL + '/EWE_on_dark.svg'} alt="EWE Logo" style={{
              width: '103px',
              height: '41px'
            }}/>
          </Link>
          <div style={{
            fontSize: '1.3rem',
            fontWeight: 'bold',
            display: 'inline-flex',
            marginLeft: '5px',
            marginBottom: '0px',
            verticalAlign: 'text-bottom',
          }}>Energize KMS</div>
        </Grid>
        <Grid item xs={3}>
          <Stack direction={"row"} spacing={4}>
            {/*<Link href={"mailto:thomas.john@ewe.de"} target={"_blank"}>*/}
            {/*  <Typography sx={{fontSize: 18, marginTop: '10px', color: '#FFFFFF' }}>*/}
            {/*    Feedback*/}
            {/*  </Typography>*/}
            {/*</Link>*/}
            {/*<Link href={"https://ewe.de"} target={"_blank"}>*/}
            {/*  <Typography sx={{ fontSize: 18, marginTop: '10px', color: '#FFFFFF' }}>*/}
            {/*    Hilfe*/}
            {/*  </Typography>*/}
            {/*</Link>*/}
          </Stack>
        </Grid>
        <Grid item xs={2}>
          {props.email && (
              <Box style={{textAlign: 'right', alignItems: 'right', marginTop: '11px', marginRight: '5px'}}>
                Hallo, {props.email}.
              </Box>
          )}

        </Grid>
        <Grid item xs={2}>
          <Box style={{textAlign: 'left', alignItems: 'left', marginTop: '6px'}}>
            <Tooltip title="Abmelden" enterDelay={1000} leaveDelay={200} TransitionComponent={Zoom} arrow>
              <Button onClick={signOut} sx={{":hover": {bgcolor: "#54C0B5"}}}>
                <LogoutIcon sx={{ color: '#FFFFFF' }}/>
              </Button>
            </Tooltip>
          </Box>
        </Grid>
      </Grid>
    </StyledHeader>
  );
};

export default Header;