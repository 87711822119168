import {useAuthenticator, View} from "@aws-amplify/ui-react";
import * as React from "react";
import {useEffect} from "react";
import {Amplify} from "aws-amplify";
import {Button} from "@mui/material";

export function SignInFooter() {

    const { toResetPassword } = useAuthenticator();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get('ticket') !== null) {
            window.localStorage.setItem('ticket', JSON.stringify(urlParams.get('ticket')))
        }
        if (urlParams.get('mail') !== null) {
            window.localStorage.setItem('mail', JSON.stringify(urlParams.get('mail')))
        }
    }, []);

    return (
        <div style={{marginBottom: '20px'}}>

            <View textAlign="center">
                <Button variant="contained" className={"secondaryButton"} style={{width: '418px', margin: 'auto', marginBottom: '20px'}} color="secondary" onClick={() => Amplify.Auth.federatedSignIn({customProvider: `azure-ewe-prod`})}>EWE-Login</Button>
            </View>

            <View textAlign="center">
                <Button
                    style={{textTransform: 'none'}}
                    onClick={toResetPassword}
                    variant="text"
                >
                    Passwort vergessen
                </Button>
            </View>
        </div>
    );
}