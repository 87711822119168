import React from "react";
import {Alert} from "@mui/material";

export interface IInfoAlertProps {
  readonly infoMessage: string,
}

export function InfoAlert(props: IInfoAlertProps) {

  return (
    <Alert severity="info" variant={"filled"}>{props.infoMessage}</Alert>
  );

}
