import React from "react";
import {Box, Skeleton} from "@mui/material";

export interface ICardSkeletonProps {
    readonly spacing?: number,
}

const CardSkeleton: React.FC<ICardSkeletonProps> = (props: ICardSkeletonProps) => {

  return (
    <>
      <Skeleton variant="text" animation="wave" width="40%" sx={{ marginLeft: '10px', fontSize: '1.3rem' }}/>
      <Skeleton variant="text" animation="wave" width="35%" sx={{ marginLeft: '10px', fontSize: '1.3rem' }}/>
      <br/>
      <Skeleton variant="text" animation="wave" width="90%" sx={{ marginLeft: '10px', fontSize: '1.3rem' }}/>
      <Skeleton variant="text" animation="wave" width="80%" sx={{ marginLeft: '10px', fontSize: '1.3rem' }}/>
      <Skeleton variant="text" animation="wave" width="75%" sx={{ marginLeft: '10px', fontSize: '1.3rem' }}/>
      <Skeleton variant="text" animation="wave" width="60%" sx={{ marginLeft: '10px', fontSize: '1.3rem' }}/>
      <br/>
      <Skeleton variant="text" animation="wave" width="90%" sx={{ marginLeft: '10px', fontSize: '1.3rem' }}/>
      <Skeleton variant="text" animation="wave" width="40%" sx={{ marginLeft: '10px', fontSize: '1.3rem' }}/>
      <br/>
      <Skeleton variant="text" animation="wave" width="30%" sx={{ marginLeft: '10px', fontSize: '1.3rem' }}/>
      <Skeleton variant="text" animation="wave" width="20%" sx={{ marginLeft: '10px', fontSize: '1.3rem' }}/>
      <Box height={props.spacing}></Box>
    </>
  );

};

export default CardSkeleton;