import * as React from 'react';
import {styled} from "@mui/material";

const Root = styled('div')(({theme}) => ({
  height: '30px',
  marginTop: '70px',
  padding: '6px',
  textTransform: 'uppercase',
  fontSize: '0.8rem',
  position: 'fixed',
  bottom: 0,
  width: '100%',
  fontWeight: 'bold',
  textAlign: 'center',
  color: '#FFFFFF',
  backgroundColor: '#5a5f5f',
}));

const Footer: React.FC = () => {
  return (
    <Root>
      powered by EWE DF und EC3
    </Root>
  );
};

export default Footer;