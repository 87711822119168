import * as React from "react";
import {useState} from "react";
import {Box, Card, Divider, Grid, IconButton, TextField, Tooltip, Typography, Zoom} from "@mui/material";
import {ContentCopy} from "@mui/icons-material";
import {GeneratedEmail} from "../../api/generated-emails-response";
import CardSkeleton from "../molecules/card-skeleton";
import toast from "react-hot-toast";
import PersonIcon from '@mui/icons-material/Person';
import ArticleIcon from '@mui/icons-material/Article';
import {ConcernDropdown} from "./concernDropdown";

export interface IAnswersCardProps {
    readonly loading: boolean,
    readonly answers: GeneratedEmail[],
    readonly selectedAnswer: string,
    readonly selectedAnswerId: string | null,
    readonly feedbackLike: number,
    readonly feedbackSent: boolean,
    readonly handleChangeAnswer: any,
    readonly handleChangeSubject: any,
    readonly handleLike: any,
    readonly handleDislike: any,
    readonly concerns: string[] | undefined,
    readonly concernMappings: any,
    readonly customerId: string | undefined,
    readonly contractId: string | undefined,
    readonly subject: string | undefined,
    readonly handleEditSelectedAnswer: any,
}

const AnswersCard: React.FC<IAnswersCardProps> = (props: IAnswersCardProps) => {

    const [selection, setSelection] = useState<any>();

    React.useEffect(() => {
        if (props.concerns && !selection) {
            const temp: any = {};
            props.concerns.forEach((concern) => {
                temp[concern] = "default";
            });
            setSelection(temp);
        }
    }, [props.concerns, selection]);

    function isArrayContentEqual(arr1: any[], arr2: any[]): boolean {
        if (arr1.length !== arr2.length) {
            return false;
        }

        const sortedArr1 = arr1.sort();
        const sortedArr2 = arr2.sort();

        for (let i = 0; i < sortedArr1.length; i++) {
            if (sortedArr1[i] !== sortedArr2[i]) {
                return false;
            }
        }

        return true;
    }

    React.useEffect(() => {
        if (props.answers && props.concerns && selection) {
            const concerns: string[] = [];
            props.concerns.forEach((concern) => {
                if (selection[concern] !== "default") {
                    concerns.push(selection[concern]);
                }
            });
            let selectedAnswer;
            props.answers.forEach((answer: any) => {
                if (isArrayContentEqual(answer.textModules, concerns)) {
                    selectedAnswer = answer;
                }
            })
            if (!selectedAnswer) {
                selectedAnswer = props.answers[0];
            }

            if (selectedAnswer) {
                props.handleChangeAnswer(selectedAnswer.generatedAnswerId);
                props.handleChangeSubject(selectedAnswer.subject);
            }

        }
    }, [props, props.answers, selection]);

    const handleChange = (concern: string, value: string) => {
        const temp = {...selection};
        temp[concern] = value;
        setSelection(temp);
    }

    function AnswerTabs() {

        return (
            <>
                {(props.answers && props.answers.length > 0 && props.concerns && selection) && (
                    <div style={{width: '100%', padding: '10px'}}>
                        <Grid spacing={2} container justifyContent="start" alignItems="center">
                            {props.concerns?.map((c) => {
                                return <Grid item md={4}>
                                    <ConcernDropdown label={c} items={props.concernMappings[c]} value={selection[c]}
                                                     handleChange={handleChange}/>
                                </Grid>
                            })}
                        </Grid>
                    </div>
                )}
            </>

        )
    }

    function copyToClipboard() {
        navigator.clipboard.writeText(props.selectedAnswer)
            .then(() => {
                    toast.success('In Zwischenablage kopiert');
                }
            );
    }

    function copySubjectToClipboard() {
        if (props.subject) {
            navigator.clipboard.writeText(props.subject)
                .then(() => {
                        toast.success('In Zwischenablage kopiert');
                    }
                );
        }
    }

    return (
        <Card>
            <Typography sx={{fontSize: 16, fontWeight: 'bold', padding: '8px'}}>Antwortvorschläge</Typography>
            <Divider light></Divider>
            <Box>
                {(!props.loading && props.answers.length > 0) ? (
                    AnswerTabs()
                ) : (
                    <Box sx={{height: '48px'}}></Box>
                )}
            </Box>
            <Divider light></Divider>
            <div style={{padding: '20px', height: '440px', overflowY: 'auto'}}>
                {!props.loading ? (
                    <>
                        <Grid container>
                            <Grid item md={11}>
                                <TextField
                                    value={props.subject}
                                    placeholder="Betreff"
                                    style={{
                                        width: '100%',
                                        paddingLeft: '8px',
                                        paddingRight: '5px',
                                        marginBottom: '20px'
                                    }}
                                    variant="standard"
                                    InputProps={{
                                        disableUnderline: false,
                                    }}
                                />
                            </Grid>
                            <Grid item md={1}>
                                <Box justifyContent="right" display="flex" style={{float: 'right'}}>
                                    <Tooltip title="In Zwischenablage kopieren" enterDelay={1000} leaveDelay={200}
                                             TransitionComponent={Zoom} arrow>
                          <span>
                            <IconButton disabled={props.selectedAnswer.length === 0} onClick={copySubjectToClipboard}>
                              <ContentCopy/>
                            </IconButton>
                          </span>
                                    </Tooltip>
                                </Box>
                            </Grid>
                        </Grid>

                        <TextField
                            value={props.selectedAnswer}
                            onChange={(e) => props.handleEditSelectedAnswer(e.target.value)}
                            multiline={true}
                            minRows={14}
                            maxRows={14}
                            placeholder="Die Antwortvorschläge sind noch nicht vorhanden."
                            style={{
                                width: '100%',
                                paddingLeft: '8px',
                                paddingRight: '5px'
                            }}
                            variant="standard"
                            InputProps={{
                                disableUnderline: true,
                            }}
                        />
                    </>
                ) : (
                    <CardSkeleton spacing={16}/>
                )}
            </div>
            <Grid container direction="row" spacing={2}>
                <Grid item xs={3}>
                    <Box sx={{display: 'flex'}}>
                        <Tooltip title="" enterDelay={1000} leaveDelay={200} TransitionComponent={Zoom} arrow>
              <span>
                {/*<Button*/}
                  {/*    sx={props.feedbackLike === 1 ? { 'backgroundColor': 'rgb(84, 192, 181, 0.25)'} : {}}*/}
                  {/*    disabled={props.feedbackSent || props.selectedAnswer.length === 0}*/}
                  {/*    onClick={props.handleLike}>*/}
                  {/*  <ThumbUpOffAlt />*/}
                  {/*</Button>*/}
              </span>
                        </Tooltip>
                        <Tooltip title="" enterDelay={1000} leaveDelay={200} TransitionComponent={Zoom} arrow>
              <span>
                {/*<Button*/}
                  {/*    sx={props.feedbackLike === 2 ? { 'backgroundColor': 'rgb(84, 192, 181, 0.25)'} : {}}*/}
                  {/*    disabled={props.feedbackSent || props.selectedAnswer.length === 0}*/}
                  {/*    onClick={props.handleDislike}>*/}
                  {/*  <ThumbDownOffAlt />*/}
                  {/*</Button>*/}
              </span>
                        </Tooltip>
                    </Box>
                </Grid>
                <Grid item xs={7}>
                    {props.feedbackSent &&
                        <Typography sx={{
                            fontSize: 14,
                            fontWeight: 'bold',
                            marginLeft: '22px',
                            marginTop: '8px',
                            color: '#54C0B5'
                        }}>
                            Danke für das Feedback!
                        </Typography>
                    }
                </Grid>
                <Grid item xs={2}>


                    <Box justifyContent="right" display="flex" style={{float: 'right'}}>
                        <Tooltip title="In Zwischenablage kopieren" enterDelay={1000} leaveDelay={200}
                                 TransitionComponent={Zoom} arrow>
                          <span>
                            <IconButton disabled={props.selectedAnswer.length === 0} onClick={copyToClipboard}>
                              <ContentCopy/>
                            </IconButton>
                          </span>
                        </Tooltip>
                    </Box>

                    {(props.contractId && props.customerId) && (
                        <Box justifyContent="right" display="flex" style={{float: 'right'}}>
                            <Tooltip title="Service 360 Vertragsansicht" enterDelay={1000} leaveDelay={200}
                                     TransitionComponent={Zoom} arrow>
                          <span>
                            <IconButton disabled={props.selectedAnswer.length === 0} onClick={() => {
                                // @ts-ignore
                                window.open(`https://service360.ewe.info/#/contract-details/customer/${props.customerId}/contract/${props.contractId}`, '_blank').focus();
                            }}>
                              <ArticleIcon/>
                            </IconButton>
                          </span>
                            </Tooltip>
                        </Box>
                    )}

                    {(props.customerId) && (
                        <Box justifyContent="right" display="flex" style={{float: 'right'}}>
                            <Tooltip title="Service 360 Kundenansicht" enterDelay={1000} leaveDelay={200}
                                     TransitionComponent={Zoom} arrow>
                          <span>
                            <IconButton disabled={props.selectedAnswer.length === 0} onClick={() => {
                                // @ts-ignore
                                window.open(`https://service360.ewe.info/#/customer/${props.customerId}`, '_blank').focus();
                            }}>
                              <PersonIcon/>
                            </IconButton>
                          </span>
                            </Tooltip>
                        </Box>)}


                </Grid>
            </Grid>
        </Card>
    );
};

export default AnswersCard;