import {Amplify, Auth, I18n} from "aws-amplify";

export const amplifyConfig = {
  Auth: {
    region: process.env.REACT_APP_USER_POOL_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    "oauth": {
      "domain": process.env.REACT_APP_OAUTH_DOMAIN,
      "scope": [
        "email",
        "openid",
        "profile"
      ],
      "redirectSignIn": process.env.REACT_APP_OAUTH_REDIRECT_SIGNIN,
      "redirectSignOut":  process.env.REACT_APP_OAUTH_REDIRECT_SIGNOUT,
      "responseType": "code"
    }
  },
  API: {
    endpoints: [
      {
        name: "energize-kms-frontend-api",
        endpoint: process.env.REACT_APP_API,
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
            'x-api-key': process.env.REACT_APP_BUILD_ENV,
          }
        }
      }
    ]
  },
}

const authScreenLabels = {
  de: {
    'Back to Sign In': 'Zurück zum Login',
    'Confirmation code cannot be empty':
      'Verifizierungs-Code darf nicht leer sein',
    'Email Address *': 'E-Mail-Adresse',
    'Enter code': 'Code eingeben',
    'Enter your Email': 'Bitte E-Mail-Adresse eingeben',
    'Enter your email': 'Bitte E-Mail-Adresse eingeben',
    'Enter your email address': 'Bitte E-Mail-Adresse eingeben',
    'Enter your username': 'Bitte E-Mail-Adresse eingeben',
    'Enter your Username': 'Bitte E-Mail-Adresse eingeben',
    'Enter your new password': 'Neues Passwort eingeben',
    'Enter your Password': 'Bitte Passwort eingeben',
    'Forgot your password?': 'Passwort vergessen?',
    'Incorrect username or password.': 'Die E-Mail-Adresse oder das Passwort ist falsch',
    'Invalid verification code provided, please try again.':
      'Ungültiger Verifizierungs-Code eingegeben. Bitte erneut versuchen',
    'New password': 'Neues Passwort (min. 9 Zeichen)',
    'Password does not conform to policy: Password not long enough': 'Das Passwort muss aus mindestens 9 Zeichen bestehen',
    'Confirm': 'Bestätigen',
    'Confirm TOTP Code': 'Login bitte mit 2-Faktor-Code bestätigen',
    'Password *': 'Passwort',
    'Password cannot be empty': 'Das Passwort-Feld darf nicht leer sein',
    'Reset Password': 'Passwort zurücksetzen',
    'Reset your password': 'Setzen Sie Ihr Passwort zurück',
    'Send Code': 'Code zuschicken',
    'Resend Code': 'Code erneut zusenden',
    'New Password': 'Neues Passwort (min. 9 Zeichen)',
    'Sign in to your account': 'Melden Sie sich an',
    'Sign In': 'Anmelden',
    'Send code': 'Zurücksetzen',
    'Sign in': 'Anmelden',
    'Username': 'E-Mail-Adresse',
    'Password': 'Passwort',
    'Change Password': 'Passwort ändern',
    'Confirm Password': 'Passwort bestätigen',
    'Please confirm your Password': 'Passwort bestätigen',
    'Signing in': 'Anmelden ...',
    'Confirming': 'Bestätigen ...',
    'User does not exist.': 'Der angegebene Benutzer wurde nicht gefunden',
    'Username cannot be empty': 'Das Feld E-Mail-Adresse darf nicht leer sein',
    'Verification code': 'Verifizierungs-Code',
    'Your passwords must match': 'Die angegebenen Passwörter stimmen nicht überein',
    'Your verification code': 'Ihr Verifizierungs-Code',
    'Username/client id combination not found.': 'Der angegebene Benutzer wurde nicht gefunden',
    'Submit': 'Abschicken',
    'Password must have at least 9 characters' : 'Das Passwort muss aus mindestens 9 Zeichen bestehen'
  },
};

I18n?.setLanguage('de');
I18n?.putVocabularies(authScreenLabels);

Amplify?.configure(amplifyConfig);

export async function signOut() {
  try {
    await Auth.signOut();
  } catch (error) {
    console.log('Error signing out user: ', error);
  }
}