import * as React from "react";
import {
  Button,
  Card, CardActions,
  CardContent,
  TextField,
  Typography,
} from "@mui/material";

export interface IFeedbackCardProps {
  readonly like: number,
  readonly text: string,
  readonly setText: any,
  readonly close: any,
  readonly submit: any,
}

const FeedbackCard: React.FC<IFeedbackCardProps> = (props: IFeedbackCardProps) => {

  return (
    <Card>
      <CardContent>
        <Typography sx={{ fontSize: 16, fontWeight: 'bold', marginBottom: '5px' }}>
          {props.like === 1
            ? "Was genau hat dir an der Antwort gefallen?"
            : "Wie können wir die Antwort verbessern?"
          }
        </Typography>
        <TextField
          value={props.text}
          onChange={(event) => {
            props.setText(event.target.value);
          }}
          multiline={true}
          minRows={1}
          placeholder="Geben Sie uns Feedback zur Antwort.."
          autoFocus={true}
          style={{
            width: '100%',
          }}
          variant="standard"
        />
      </CardContent>
      <CardActions>
        <Button onClick={props.submit}>Senden</Button>
        <Button onClick={props.close}>Schließen</Button>
      </CardActions>
    </Card>
  );
};

export default FeedbackCard;