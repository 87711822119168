import {API} from "aws-amplify";
import {EmailResponse} from "./email-response";
import {GeneratedEmail, GeneratedEmailsResponse} from "./generated-emails-response";
import axios from "axios";

export const getEmail = async (ticketId: string, emailId: string): Promise<EmailResponse> => {
  const apiName = 'energize-kms-frontend-api';
  const path = `email?ticket=${ticketId}&mail=${emailId}`;
  const request = {
    response: false, // Only return axios data
  };

  try {
    let response = await API.get(apiName, path, request) as EmailResponse;
    return {
      ...response,
      statusCode: 200,
    }
  } catch (e) {
    let statusCode: number = 500;
    console.log('Fehler beim Abrufen der Frontend-API.');

    if (axios.isAxiosError(e)) {
      console.log(e.response?.data);

      if (e.response?.status) {
        statusCode = e.response.status;
      }
    }

    return {
      emailId: '',
      summary: '',
      concerns: [],
      statusCode: statusCode,
      metaData: '',
      message: '',
      customerNr: '',
      contractNr: '',
      concernsMapping: '',
      subject: ''
    }
  }

}

export const getGeneratedEmails = async (ticketId: string, emailId: string): Promise<GeneratedEmailsResponse> => {
  const apiName = 'energize-kms-frontend-api';
  const path = `answer?ticket=${ticketId}&mail=${emailId}`;
  const request = {
    response: false, // Only return axios data
  };

  try {
    let response = await API.get(apiName, path, request) as GeneratedEmail[]
    response = response.sort((a: GeneratedEmail, b: GeneratedEmail) => {
      return Date.parse(b.date) - Date.parse(a.date)
    });
    return {
      generatedEmails: response,
      statusCode: 200,
    }
  } catch (e) {
    let statusCode: number = 500;
    console.log('Fehler beim Abrufen der Frontend-API.');

    if (axios.isAxiosError(e)) {
      console.log(e.response?.data);

      if (e.response?.status) {
        statusCode = e.response.status;
      }
    }

    return {
      generatedEmails: [],
      statusCode: statusCode,
    }
  }
}

export const postFeedback = async (ticketId: string, emailId: string, answerId: string, feedbackLike: number, feedbackText: string): Promise<boolean> => {
  const apiName = 'energize-kms-frontend-api';
  const path = `feedback?ticket=${ticketId}&mail=${emailId}&answer=${answerId}`;
  const request = {
    response: false, // Only return axios data
    body: {
      feedbackLike: feedbackLike,
      feedbackText: feedbackText,
    }
  };

  try {
    await API.patch(apiName, path, request);
    return true;
  } catch (e) {
    console.log('Fehler beim Abrufen der Frontend-API.');
    console.log(e);
    return false;
  }

}