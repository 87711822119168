import React from 'react';
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";

export function ConcernDropdown(props: any) {
  return (
    <>
        <FormControl fullWidth>
            <InputLabel id={props.label}>{props.label}</InputLabel>
            <Select
                labelId={props.label}
                id={props.label}
                style={{borderRadius: '10px'}}
                value={props.value}
                label={props.label}
                onChange={(e) => props.handleChange(props.label, e.target.value)}
            >
                <MenuItem value={'default'}>Standard</MenuItem>
                {props.items?.map((i: any) => {
                    return <MenuItem value={i}>{i}</MenuItem>
                })}
            </Select>
        </FormControl>
    </>
  );
}
