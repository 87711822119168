import {useEffect, useLayoutEffect, useState} from "react";
import {getEmail, getGeneratedEmails, postFeedback} from "../../api/energize-frontend-api";
import {GeneratedEmail} from "../../api/generated-emails-response";
import {Box, Grid, LinearProgress} from "@mui/material";
import AnswersCard from "../organisms/answers-card";
import FeedbackCard from "../organisms/feedback-card";
import {ErrorAlert} from "../molecules/error-alert";
import DocumentsCard from "../organisms/document-card";
import SummaryCard from "../organisms/summary-card";
import {InfoAlert} from "../molecules/info-alert";

export function EmailAssistentPage() {
  const [ticketId, setTicketId] = useState<string | null>(null);
  const [mailId, setMailId] = useState<string | null>(null);
  const [selectedAnswerId, setSelectedAnswerId] = useState<string | null>(null);
  const [selectedAnswer, setSelectedAnswer] = useState<string>("");
  const [answers, setAnswers] = useState<GeneratedEmail[]>([]);
  const [originalEmail, setOriginalEmail] = useState("");
  const [originalEmailMessage, setOriginalEmailMessage] = useState("");
  const [concerns, setConcerns] = useState<string[]>();
  const [concernMappings, setConcernMappings] = useState<any>();
  const [feedbackLike, setFeedbackLike] = useState(0);
  const [feedbackText, setFeedbackText] = useState("");
  const [feedbackSent, setFeedbackSent] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [infoMessage, setInfoMessage] = useState("");
  const [customerId, setCustomerId] = useState<string | undefined>();
  const [contractId, setContractId] = useState<string | undefined>();
  const [subject, setSubject] = useState<string | undefined>();
  const [selectedFacts, setSelectedFacts] = useState<any[] | undefined>();

  useLayoutEffect(() => {
    const urlParams = new URLSearchParams(document.location.search);
    const ticketParam = urlParams.get('ticket');
    const mailParam = urlParams.get('mail');

    if (ticketParam === null || mailParam === null) {

      if (window.localStorage.getItem('ticket') !== null && window.localStorage.getItem('mail') !== null) {
        // @ts-ignore
        urlParams.set('ticket', JSON.parse(window.localStorage.getItem('ticket')));
        // @ts-ignore
        urlParams.set('mail', JSON.parse(window.localStorage.getItem('mail')));
        // @ts-ignore
        window.location.search = urlParams;
        return;
      }
      else {
        setInfoMessage("Die Zusammenfassung und Antwortvorschläge sind nicht vorhanden. Bitte prüfen Sie die URL in der Adresszeile des Browsers.")
        setLoading(false);
        return;
      }
    }

    setTicketId(ticketParam);
    setMailId(mailParam);

    if (ticketId != null && mailId != null) {
      getEmail(ticketId, mailId)
        .then(response => {
          if (response.statusCode !== 200) {

            if (response.statusCode === 404) {
              setInfoMessage("Die Zusammenfassung ist nicht vorhanden. Bitte prüfen Sie die URL in der Adresszeile des Browsers.")
            } else {
              setErrorMessage("Beim Abruf der Zusammenfassung ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut!")
            }
          }

          setCustomerId(response.customerNr);
          setContractId(response.contractNr);
          setConcernMappings(response.concernsMapping);

          if (response.summary && response.summary.length > 1) {
            setOriginalEmail(response.summary);
            setOriginalEmailMessage(response.message);
          } else {
            setLoading(false);
            return;
          }

          if (response.concerns && response.concerns.length > 0) {
            setConcerns(response.concerns);
          }
        });
    }

    if (ticketId != null && mailId != null) {
      getGeneratedEmails(ticketId, mailId)
        .then(response => {
          if (response.statusCode !== 200) {
            if (response.statusCode === 404) {
              setInfoMessage("Es sind keine Antwortvorschläge vorhanden. Bitte prüfen Sie die URL in der Adresszeile des Browsers.")
            } else {
              setErrorMessage("Beim Abruf der Antwortvorschläge ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut!")
            }
          }

          if (response.generatedEmails && response.generatedEmails.length > 0) {
            setAnswers(response.generatedEmails);
            // Show the first answer per default
            if (selectedAnswerId == null) {
              setSelectedAnswerId(response.generatedEmails[0].generatedAnswerId)
            }
          } else {
            setLoading(false);
            return;
          }
        });
    }
  }, [ticketId, mailId, selectedAnswerId]);

  useEffect(() => {
    if (selectedAnswerId && answers.length > 0) {
      const matchedAnswer = answers.find((answer) => answer.generatedAnswerId === selectedAnswerId)
      if (matchedAnswer) {
        setSelectedAnswer(answers.find((answer) => answer.generatedAnswerId === selectedAnswerId)!.answer)
      } else {
        // If no answer is present for the selectedAnswerId, choose first one
        setSelectedAnswerId(answers[0].generatedAnswerId)
        setSelectedAnswer(answers[0].answer)
      }
      setLoading(false);
    }
  }, [selectedAnswerId, answers]);

  function handleChangeAnswer(generatedAnswerId: string) {
    setSelectedAnswerId(generatedAnswerId);
    setFeedbackSent(false);
    answers.forEach((mail) => {
      if (mail.generatedAnswerId === generatedAnswerId) {
        setSelectedFacts(mail.fact);
      }
    })
  }

  async function handleLike() {
    if (!feedbackSent) {
      setFeedbackLike(1);
    }
  }

  async function handleDislike() {
    if (!feedbackSent) {
      setFeedbackLike(2);
    }
  }

  async function submitFeedback() {
    if (ticketId != null && mailId != null && selectedAnswerId != null) {
      postFeedback(ticketId, mailId, selectedAnswerId, feedbackLike, feedbackText)
        .then((successful) => {
          if (successful) {
            setFeedbackSent(true);
            setFeedbackText("");
            setFeedbackLike(0);
          } else {
            setErrorMessage("Das Feedback konnte nicht gesendet werden. Bitte versuchen Sie es später erneut!")
            return
          }
        });

      setTimeout(() => {
        setFeedbackSent(false);
      }, 5000);
    }
  }

  async function closeFeedback() {
    setFeedbackLike(0);
  }

  return(
    <>
      <Box sx={{ height: '5px' }}>
        {loading &&
            <LinearProgress></LinearProgress>
        }
      </Box>

      <main>
        <Grid container direction="row" spacing={4} margin={2}>
          <Grid item xs={12}>
            {errorMessage.length > 0 &&
                <ErrorAlert errorMessage={errorMessage}></ErrorAlert>
            }
            {infoMessage.length > 0 &&
                <InfoAlert infoMessage={infoMessage}></InfoAlert>
            }
          </Grid>
        </Grid>

        <Grid container justifyItems={'stretch'} justifyContent={'stretch'} direction="row" spacing={4} margin={2}>
          <Grid item xs={6}>
            {originalEmail && (
                <SummaryCard loading={loading}
                             concerns={concerns}
                             originalEmailMessage={originalEmailMessage}
                             originalEmail={JSON.parse(originalEmail)} />
            )}

          </Grid>
          <Grid item xs={6}>
            <AnswersCard loading={loading}
                         answers={answers}
                         customerId={customerId}
                         contractId={contractId}
                         selectedAnswer={selectedAnswer}
                         handleEditSelectedAnswer={setSelectedAnswer}
                         selectedAnswerId={selectedAnswerId}
                         feedbackLike={feedbackLike}
                         handleChangeSubject={setSubject}
                         concerns={concerns}
                         concernMappings={concernMappings}
                         feedbackSent={feedbackSent}
                         subject={subject}
                         handleChangeAnswer={handleChangeAnswer}
                         handleLike={handleLike}
                         handleDislike={handleDislike} />
          </Grid>

            <Grid container direction="row" spacing={4} margin={0}>
              <Grid item xs={6}>
                <DocumentsCard loading={loading}
                               documents={selectedFacts ? selectedFacts.map(f => f.fileName) : []} />
              </Grid>

              {feedbackLike !== 0 && !feedbackSent &&
                  <Grid item xs={6}>
                    <FeedbackCard
                        like={feedbackLike}
                        text={feedbackText}
                        setText={setFeedbackText}
                        submit={submitFeedback}
                        close={closeFeedback} />
                  </Grid>
              }
            </Grid>
          </Grid>
        </main>
      </>
  );

}